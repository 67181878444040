import React, { useEffect } from 'react'
import loadable from '@loadable/component'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import BlogRoll from '../components/BlogRoll'

const Typewriter = loadable(() => import('typewriter-effect'))

const PaginationLink = props => {
  if (!props.test) {
    return (
      <Link to={`/blog/${props.url}`} className='btnblog'>
        {props.text}
      </Link>
    )
  } else {
    return (
      <span disabled className='btnblogdisabled'>
        {props.text}
      </span>
    )
  }
}



const BlogPage = (props) => {
  const { pageContext: { first, group, index, last } } = props
  const previousUrl = index - 1 === 1 ? '' : (index - 1).toString()+'/'
  const nextUrl = (index + 1).toString()+'/'
  useEffect(() => {
        document.getElementById('lang-switch').href = 'https://e-multicontent.com/blog/'
   });
   // let stringIndex = index.toString()
    return (
      <>
      <Helmet titleTemplate="%s">



        <meta name='image' content={'https://e-multicontent.pl/img/meta/blog-meta.jpg'} />
        <meta name='keywords' content={'copywriting, copywriterzy, marketing, agencja copywriterska, Wrocław, zespół copywriterów, praca, biznes, treści, content, tłumaczenia, SEO, blog, teksty, artykuły, grafika, video, strony internetowe, sklepy internetowe, marketplace, e-commerce'} />
        <meta property="og:description" content={'Zajrzyj na Naszego bloga o copywritingu. Regularnie dostarczamy tu świeże i wartościowe treści! ✔️'}/>
        <meta property='og:title' content={'Blog - wszystko o copywritingu i e-commerce | e-multicontent'} />
        <meta property='og:image' content={'https://e-multicontent.pl/img/meta/blog-meta.jpg'} />
        <meta property="og:url" content={'https://e-multicontent.pl/blog/'}/>

        <link rel="alternate" href="https://e-multicontent.com/blog/" hrefLang="en-gb" />
        <link rel="alternate" href="https://e-multicontent.com/blog/" hrefLang="en-us" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="e-multicontent.pl" />
        <meta property="twitter:url" content="https://e-multicontent.pl/blog/" />
        <meta name="twitter:title" content="Blog - wszystko o copywritingu i e-commerce | e-multicontent" />
        <meta name="twitter:description" content="Zajrzyj na Naszego bloga o copywritingu. Regularnie dostarczamy tu świeże i wartościowe treści! ✔️" />
        <meta name="twitter:image" content="https://e-multicontent.pl/img/apple-touch-icon.png" />

        <script type='application/ld+json'>
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "name": "Home",
            "item": "https://e-multicontent.pl/"
          },{
            "@type": "ListItem",
            "position": 2,
            "name": "Blog",
            "item": "https://e-multicontent.pl/blog/"
          }]
        })}
        </script>

      </Helmet>
      {index === 1 ?
      <Helmet>
      <title>Blog - wszystko o copywritingu i e-commerce | e-multicontent</title>
      <link rel="canonical" href="https://e-multicontent.pl/blog/"  />
      <meta name="description" content={'Zajrzyj na Naszego bloga o copywritingu. Regularnie dostarczamy tu świeże i wartościowe treści! ✔️'}/>
      </Helmet>
      :
      <Helmet>
      <meta name="robots" content="noindex, nofollow" />
      <title>{'Strona ' + index + ' - Blog - wszystko o copywritingu i e-commerce | e-multicontent'}</title>
      <meta name="description" content={'Zajrzyj na Naszego bloga o copywritingu. Regularnie dostarczamy tu świeże i wartościowe treści! ✔️ Strona ' + index}/>
      <link rel="canonical" href={'https://e-multicontent.pl/blog/'+ index +'/'}  />
      </Helmet>
      }
      <Layout>
      <nav className="breadcrumbs-nav section">
          <Link className="inline-link" to="/">Home</Link>&nbsp;&gt;&nbsp;<span style={{fontWeight:'600'}}><b>Blog</b></span>
      </nav>
        <div
          className="full-width-image-container margin-top-20"
          id="blog"
          style={{
            backgroundPosition: `center right`,
            backgroundSize:'cover',
            transform: 'scale(1)',
            height:'600px',
            backgroundRepeat: 'no-repeat',
            paddingTop: '0px',

          }}
        >

          </div>

          <p className="ux" align="center" style={{marginTop:'-75px',marginBottom:'200px'}} id="artykuly"><a className="btnoutline" href="#artykuly">⇩</a></p>

                    <h1
                      className="has-text-weight-bold is-size-1 mright"
                      style={{
                        color: '',
                        lineHeight: '1',
                        padding: '0.35em',
                        borderRadius:'10px',
                        marginTop: '0%',
                        display: 'block',
                        position: 'absolute',
                        left: '7%',
                        top: '200px',
                        textAlign: 'left',
                        backgroundColor:'rgba(255,255,255,0.7)',
                      }}
                    >
                    Zajrzyj na&nbsp;<b style={{color:'#36b3d2'}}>bloga</b>
                    <br></br>
                    </h1>
                    <br></br>
                    <h2
                    className="mright"
                    style={{
                      color: 'rgb(23,19,43)',
                      lineHeight: '1',
                      padding: '0.35em',
                      borderRadius:'10px',
                      marginTop: '0%',
                      display: 'block',
                      position: 'absolute',
                      left: '8%',
                      top: '350px',
                      textAlign: 'left',
                    backgroundColor:'rgba(255,255,255,0.8)',}} >Regularnie dostarczamy tu świeże i wartościowe treści!</h2>

        <section>
          <div  className="container">
            <div id="blogposts" style={{marginTop: '-230px'}} className="content">
              <BlogRoll posts={group} />
            </div>
            <div className='buttons is-centered' style={{marginTop:'25px'}}>
              <PaginationLink test={first} url={previousUrl} text='<' />
              <PaginationLink test={last} url={nextUrl} text='>' />
            </div>
            <br></br>
            <br></br>
            <div align="center">

            </div>
            <h3 className="is-size-2 has-text-weight-bold" align="center">
            <Typewriter
                onInit={(typewriter) => {
                  typewriter.typeString("<span style='color:#36b3d2'>Dziękujemy</span> za wizytę!")
                    .pauseFor(2500)
                    .deleteAll()
                    .start();
                }}
                options={{
                  autoStart: true,
                  loop: true,
                }}
              />

            </h3>
            </div>

            <br></br>

        </section>
      </Layout>
      </>
    )
  }

  export default BlogPage
