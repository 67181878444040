import React from 'react'
import { Link } from 'gatsby'
import Img from "gatsby-image"

import facebook from '../img/social/facebook-logo.svg'
import linkedin from '../img/social/linkedin-logo.svg'
import twitter from '../img/social/twitter-logo.svg'
import { kebabCase } from 'lodash'






const BlogRoll = (props) => {
  const { posts } = props

  return (
      <div className="columns is-multiline" style={{marginTop:'50px'}}>
        {posts &&
          posts
          .map(({ node: post }) => (
            <div className="is-parent column is-6" key={post.id}>
              <article
                className={`blog-list-item tile is-child box notification ${
                  post.frontmatter.featuredpost ? 'is-featured' : ''
                }`}
                style={{minHeight:'450px',}}
              >
                <header>
                  {post.frontmatter.featuredimage ? (
                    <div className="featured-thumbnail" >
                    <Link
                      to={post.fields.slug}
                    >
                    <Img className="dynamic-img column" fluid={post.frontmatter.featuredimage.childImageSharp.fluid}
                     alt={post.frontmatter.title}
                     title={post.frontmatter.title}
                     imgStyle={{objectFit:'contain',position:'relative',borderRadius:'15px',minWidth:'200px',minHeight:'150px',width:'100%'}}
                     />
                     </Link>
                    </div>
                  ) : null}
                  <p className="post-meta " style={{width:'100%'}}>
                    <Link
                      className="title"
                      style={{color:'#222222',fontSize:'1.3em'}}
                      to={post.fields.slug}
                    >
                      {post.frontmatter.title}
                    </Link>
                    <br />
                    <span className="is-size-5">
                      {post.frontmatter.date}
                    </span>
                    <br />
                    <span style={{fontSize:'.9em',fontWeight:'normal'}}>&bull; Czas czytania: {post.timeToRead} min </span>
                  </p>
                </header>
                <p>
                  {post.frontmatter.description.slice(0,400) + '...'}
                  <br />
                  <br />
                  <Link className="btnblog" style={{ boxShadow: '5px 5px 10px rgba(23,19,43,0.5)',}} to={post.fields.slug}>
                    Czytaj dalej...
                  </Link>
                  <br></br><br></br>

                  <span style={{marginTop:'10px',marginBottom:'10px'}}>
                  <Link className="categblog" style={{fontSize:'12px'}} to={`/tagi/${kebabCase(post.frontmatter.tags.slice(0,1))}/`}>&bull; {post.frontmatter.tags.slice(0,1)} </Link>
                  &nbsp;&nbsp;
                  <Link className="categblog" style={{fontSize:'12px'}} to={`/tagi/${kebabCase(post.frontmatter.tags.slice(1,2))}/`}>&bull; {post.frontmatter.tags.slice(1,2)} </Link>
                  &nbsp;&nbsp;
                  <Link className="categblog" style={{fontSize:'12px'}} to={`/tagi/${kebabCase(post.frontmatter.tags.slice(2,3))}/`}>&bull; {post.frontmatter.tags.slice(2,3)} </Link>
                  </span>
                  <br /><br />
                  {/* can add box for rows layout*/}
                  <span style={{width:'100%',display:'flex',justifyContent:'center'}}>
                  <a style={{padding:'5px',margin:'0px'}} href={'https://www.facebook.com/sharer/sharer.php?u=https://e-multicontent.pl'+post.fields.slug} target="_blank" rel="noopener noreferrer nofollow"><img alt="facebook" style={{width:'25px'}} src={facebook}/></a>&nbsp;
                  <a style={{padding:'5px',margin:'0px'}} href={'https://twitter.com/home?status=https://e-multicontent.pl'+post.fields.slug+' Przeczytaj więcej na blogu e-multicontent.pl'} target="_blank" rel="noopener noreferrer nofollow"><img alt="twitter"  style={{width:'25px'}} src={twitter}/></a>&nbsp;
                  <a style={{padding:'5px',margin:'0px'}} href={'https://www.linkedin.com/shareArticle?mini=true&url=https://e-multicontent.pl'+post.fields.slug+'&title=&summary=Przeczytaj więcej na blogu e-multicontent.pl&source='} target="_blank" rel="noopener noreferrer nofollow"><img alt="linkedin" style={{width:'25px'}} src={linkedin}/></a>&nbsp;
                  </span>
                </p>
              </article>
            </div>
          ))}
      </div>


    )
  }


export default BlogRoll
